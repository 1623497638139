/* line 32, scss/80-themes/AffinityDefault/60-components/prev-next-controls.scss */
.c-prev-next-ctrls-label {
	font-size: 0.9em;
	font-weight: 500;
	letter-spacing: normal;
	color: rgb(var(--color-global-secondary));
}

/* line 39, scss/80-themes/AffinityDefault/60-components/prev-next-controls.scss */
.c-prev-next-ctrls {
	padding: 20px 0;
	user-select: none;
	display: flex;
	align-items: center;
}

/* line 46, scss/80-themes/AffinityDefault/60-components/prev-next-controls.scss */
.c-prev-next-ctrls-input {
	font-weight: 400;
	user-select: none;
	opacity: 1;
	height: 100%;
	color: rgb(var(--color-global-secondary));
	font-size: 1.34em;
	border: 0;
}

/* line 56, scss/80-themes/AffinityDefault/60-components/prev-next-controls.scss */
.c-prev-next-ctrls-btn {
	flex: 0 0 auto;
}

/* line 60, scss/80-themes/AffinityDefault/60-components/prev-next-controls.scss */
.c-prev-next-ctrls-btn.o-button--round {
	padding: 15px;
	letter-spacing: 0;
}

/* line 64, scss/80-themes/AffinityDefault/60-components/prev-next-controls.scss */
.c-prev-next-ctrls-btn.o-button--round .icon {
	display: block;
}

/* line 69, scss/80-themes/AffinityDefault/60-components/prev-next-controls.scss */
.icon--prevnext {
	color: rgb(var(--color-global-secondary));
	font-size: 0.834em;
}

/* line 74, scss/80-themes/AffinityDefault/60-components/prev-next-controls.scss */
.c-prev-next-ctrls-value {
	display: inline-block;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0 5px;
	text-align: center;
	min-width: 60px;
	flex: 0 1 auto;
}

@media only screen and (max-width: 29.999em) {
	/* line 74, scss/80-themes/AffinityDefault/60-components/prev-next-controls.scss */
	.c-prev-next-ctrls-value {
		min-width: 40px;
	}
}

/* line 86, scss/80-themes/AffinityDefault/60-components/prev-next-controls.scss */
.prev-next-control-disabled {
	opacity: 0.45;
}

/*# sourceMappingURL=../../../../true */